import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import Passi from "../../components/Passi"
import Aos from "aos"
import defaultImage from "../../images/default.png"
import { graphql, Link, useStaticQuery } from "gatsby"
import slugify from '@sindresorhus/slugify'

const ContiCorrenti = () => {
  const data = useStaticQuery(query)
  const items = data.allMysqlPrograms.nodes
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  return (
    <Layout>
      <Seo title="Conti correnti e carte" />
      <section className="mt-24">
        <div data-aos="fade" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-bold  text-sky-900 sm:text-4xl text-center">
              Offerta Conti correnti e carte
            </p>
            <p className="mt-4 max-w-2xl text-xl text-sky-800 lg:mx-auto">
              <span className="text-lg leading-6 font-semibold text-sky-700 mr-1">
                Qui trovi conti correnti,
              </span>
              conti deposito e carte prepagate retail
              o business con le migliori challenger bank attualmente sul
              mercato. Scegli il prodotto a te più congeniale tra quelli da noi
              proposti e apri il tuo conto in pochi passi. Ti basta essere
              maggiorenne e risiedere in Italia.
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {items &&
              items.map(item => {
                return (
                  <div
                    key={item.mysqlId}
                    className="py-8 md:py-4 w-full min-h-80 bg-amber-50 rounded-xl shadow-lg lg:h-80 border border-amber-100"
                  >
                    <div className="px-6 py-1 flex justify-center">
                      <img
                        className="mx-auto h-auto w-auto max-h-14 lg:h-20 sm:mx-0 sm:shrink-0 my-4"
                        src={
                          item.logo
                            ? `https://clicknpay.it/static-images/programs/${item.logo}`
                            : defaultImage
                        }
                        alt={item.name}
                      />
                    </div>
                    <hr className="m-8" />
                    <div className="text-center">
                      <p className="text-lg text-black font-semibold">
                        {item.name.replace(/_CPL/g, " ")}
                      </p>
                      <Link
                        to={`/servizi/${slugify(item.name)}-${item.mysqlId}`}
                        className="px-2 py-1 mt-4 text-sm text-sky-900 font-medium inline-flex items-center justify-between rounded-md border border-sky-900  hover:bg-super hover:border-transparent hover:transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-super focus:ring-offset-2"
                      >
                        Scopri di più
                      </Link>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="py-16">
            <h4 className="text-lg text-orange-700 font-semibold tracking-widest uppercase text-center py-8">
              Come Funziona
            </h4>
            <Passi />
          </div>
        </div>

      </section>
    </Layout>
  )
}

export default ContiCorrenti
const query = graphql`
  {
    allMysqlPrograms(
      filter: { enabled: {eq: "1"}, products: { elemMatch: { category_id: { in: 2 } } } }
    ) {
      nodes {
        id
        mysqlId
        logo
        name
        enabled
        products {
          category_id
          display_name
          reflink
          json_metadata
        }
      }
    }
  }
`
